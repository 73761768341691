
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import FormBodyProduct from "@/components/occurrences/FormBodyProduct.vue";
import store from "@/store";
import FieldErrorMessage from "@/components/global/FieldErrorMessage.vue";
import ApiService from "@/core/services/ApiService";
import {useRouter} from "vue-router";
import OccurenceDetailTable from "@/components/customTables/OccurenceDetailTable.vue";

const router = useRouter();
/* eslint-disable */
export default defineComponent({
  name: "Conclusion",
  components: {
    OccurenceDetailTable,
  },
  props: {
    incNmr: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      occurence: {},
      file: File,
      statusList: [],
    };
  },

  computed: {
    occStatusColors() {

      switch (this.occurence["codEstado"]) {
        case 3:
          return "background-color: #D8FFE7; color: black !important; min-width:150px; border: 1px solid #2CC268;";
        case 1:
          return "background-color: #FEF3D8; color: black !important; min-width:150px; border: 1px solid #FFB01A;";
        default:
          return "background-color: #dfdddd; color: black !important; min-width:150px; border: 1px solid #181c32;";
      }
    },
    statusDesc() {
      let status = this.statusList.find((item: {strCodigo:string, strDescricao:string}) => item.strCodigo == this.occurence["codEstado"]);
      if(!status) {
        return "Não definido"
      }
      return status['strDescricao'];
    },
    observation() {
      //iterate and return html string
      //replace \n with <br>
      return this.occurence["obs"];
    },
  },
  methods: {

    /*
    * ----------------DATA MANIPULATION----------------
    */

    setfile(decodedFile) {
      this.file = decodedFile;
      //console.log(this.file)
    },

    /*
    * ------------------API FETCHING------------------
     */

    loadOccurence() {



      ApiService.get("incidencias/" + this.incNmr)
          .then(({data}) => {
            this.occurence = data.Data;

          })
          .catch(({data}) => {
            console.log(data);
          });
    },

    loadStatusList() {

      ApiService.get("Utils/estadosDocsOccurences")
          .then(({data}) => {
            this.statusList = data.Data;
          })
          .catch(({data}) => {
            console.log(data);
          });
    },

    /*
    * ------------------EMITS------------------
    */

    /*
    * -------------------UTILS--------------------
    */

    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },

    back() {
      this.$router.go(-1);
    },

    /*
    * -------------------STYLES-------------------
     */


  },
  mounted() {
    this.loadOccurence();
    this.loadStatusList();
    setCurrentPageTitle("Detalhe Incidência");
  },
});
